/*!
 * Evil Corp (https://evilcorp.com.ar/)
 * Copyright 2004-2018 EvilCorp S.A.
 */


/* Typography */


.h1, .h2, .h3, .h4, .h5, h1, h2, h3, h4, h5{
	font-family: "objektiv-mk1", $font-family-sans-serif;
}

/* Layout */

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}


%separator{
	color: $gray-500;
	font-weight: 400;
	padding-bottom: ($spacer * 4);
	&::after {
		content: ' ';
		display: block;
		width: 44px;
		padding-top: ($spacer * 3);
		font-size: 20px;
		border-bottom: 6px solid $gray-500;
		margin-left: auto;
		margin-right: auto;
	}
}

.separator{
	@extend %separator;
}

ul.dash-list {
	margin: 5px 0 35px;
	padding: 0;
	li {
		list-style: none;
		position: relative;
		padding: 0 0 0 30px;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 16px;
			width: 16px;
			display: block;
			border-top: 2px solid $gray-700;
		}
	}
}



/*  Pages --------------------  */


/* Home */

.page-home{
	background: url(../img/stars-red.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #000000;
	.display-3{
		font-size: 2.0em;
	}
}

@include media-breakpoint-up(md) {
	.page-home{
		.hero{
			text-align: center;
		}
		.display-3{
			font-size: 4.5em;
		}
	}
}
@include media-breakpoint-up(xxl) {
	.page-home .lead{
		max-width: 900px;
		margin-left: auto;
		margin-right: auto;
	}
}


.testimonials{
	.testimonial{

		text-align: center;

		.name{
			font-weight: 700;
		}
		.company-and-position{

		}
		.statement{
			font-size: 2.5rem;
			font-weight: 900;
			line-height: 1.2;
		}
	}
}


/* services – What we Do */

.capabilities section h3{
	font-size: 3rem;
}

/*
.capabilities section h3::before {
    content: ' ';
    display: block;
    width: 66px;
    padding-bottom: ($spacer * 3);
    font-size: 20px;
    background-color: #FFF;
    border-top: 6px solid $gray-500;
}
*/


.page-services{
	.teaser{
		color: $gray-500;
		padding-bottom: ($spacer * 1);
		font-weight: 400;
	}
	.display-2{
		font-size: 3.0em;
	}
	.mainfeature .lead{
		color: $text-muted;
	}
}


@include media-breakpoint-up(md) {

	.page-services{
		.teaser{
			padding-bottom: ($spacer * 4);
			font-weight: 400;

			&::after {
				content: ' ';
				display: block;
				width: 44px;
				padding-top: ($spacer * 3);
				font-size: 20px;
				border-bottom: 6px solid $gray-500;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.display-2{
			font-size: 4.5em;
		}
		.mainfeature {
			text-align: center;
		}
		.mainfeature .lead{
			color: $text-muted;
			max-width: 1100px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

/* about */

.page-about{
	.teaser{
		color: $gray-500;
		font-weight: 400;
		padding-bottom: ($spacer * 1);
	}
	.display-2{
		font-size: 2.5em;
	}
}

@include media-breakpoint-up(md) {
	.page-about{
		.teaser{
			padding-bottom: ($spacer * 4);
			&::after {
				content: ' ';
				display: block;
				width: 44px;
				padding-top: ($spacer * 3);
				font-size: 20px;
				border-bottom: 6px solid $gray-500;
			}
		}
		.display-2{
			font-size: 4.0em;
		}
		.about,
		.leadership{
			max-width: 960px;
		}
	}
}

/* Join */

.page-join{
	.teaser{
		color: $gray-500;
		font-weight: 400;
		padding-bottom: ($spacer * 1);
	}
	.display-2{
		font-size: 2em;
	}
}

@include media-breakpoint-up(md) {
	.page-join{
		.teaser{
			padding-bottom: ($spacer * 4);
			&::after {
				content: ' ';
				display: block;
				width: 44px;
				padding-top: ($spacer * 3);
				font-size: 20px;
				border-bottom: 6px solid $gray-500;
			}
		}
		.display-2{
			font-size: 4.0em;
		}
	}
}

/* Contact */

.page-contact{
	.teaser{
		color: $gray-500;
		font-weight: 400;
		padding-bottom: ($spacer * 1);
	}
	.display-3{
		font-size: 2.5em;
	}
}

@include media-breakpoint-up(md) {
	.page-contact{
		.teaser{
			padding-bottom: ($spacer * 4);
			&::after {
				content: ' ';
				display: block;
				width: 44px;
				padding-top: ($spacer * 3);
				font-size: 20px;
				border-bottom: 6px solid $gray-500;
			}
		}
		.display-3{
			font-size: 4.0em;
		}
	}
}


/* Mediakit */

.page-mediakit{
	.teaser{
		color: $gray-500;
		font-weight: 400;
		padding-bottom: ($spacer * 1);
	}
	.display-3{
		font-size: 2.5em;
	}
	.logos{
		svg {
			height: 100px;
		}
	}
}

@include media-breakpoint-up(md) {
	.page-mediakit{
		.teaser{
			padding-bottom: ($spacer * 4);
			&::after {
				content: ' ';
				display: block;
				width: 44px;
				padding-top: ($spacer * 3);
				font-size: 20px;
				border-bottom: 6px solid $gray-500;
			}
		}
		.display-3{
			font-size: 4.0em;
		}
	}
}



/* Cases ---------------- */



.container.container-narrow{
	max-width: 740px;
}

.separator-2{
	color: $gray-500;
	font-weight: 600;
	padding-bottom: ($spacer * 4);
	&::after {
		text-align: center;
		content: ' * * * ';
		display: block;
		padding-top: ($spacer);
		font-size: 40px;
		letter-spacing: 8px;
		border-bottom: none;
		margin-left: auto;
		margin-right: auto;
	}
}

.page-case{
	.project-file{
		h5, p{
			font-size: .875rem;
		}
		h5{
			text-transform: uppercase;
		}
	}
	.cases-index{
		margin-bottom: ($spacer*15);
	}
}



/* expenses */


.page-case.page-case-expenses{
	.header-inverse{
		background-color: #CE4169;
		color: #FFF;
	}
	.hero{
		.title{
			padding-top: 3rem;
			.teaser{
				margin-bottom: 6rem;
			}
		}
		.featured-screen{
			img{
				display: block;
				margin-left: auto;
				margin-right: auto;
				max-width: 500px;
			}
		}
	}
	.project-mobile-screens{
		background-color: #1C4265;
	}
	.other-cases .case-expenses {
		opacity: .5;
		.btn {
			pointer-events: none;
			cursor: default;
		}
	}
}

@include media-breakpoint-up(md) {
	.page-case.page-case-expenses{
		.project-mobile-screens{
			p{
				font-size: .875rem;
				color: #FFF;
				margin-bottom: 4rem;
			}
			.col-img-1{
				padding-top: 3rem;
			}
			.col-img-2{
				padding-top: 12rem;
			}
			.col-img-3{
				padding-top: 8rem;
			}
			.col-img-4{
				padding-top: 8rem;
			}
			.row > div{
				margin-bottom: -28rem;
			}
		}
		.project-conclusion{
			margin-top: 32rem;
		}
	}
}


/* nikesb */

.page-case.page-case-nikesb{
	.header-inverse{
		background-color: #666;
		color: #FFF;
	}
	.hero{
		.title{
			padding-top: 1rem;
			.teaser{
				margin-bottom: 3rem;
			}
			.display-1{
				font-size: 4rem;
			}
		}
		.featured-screen{
			img{
				display: block;
				margin-left: auto;
				margin-right: auto;
				max-width: 500px;
			}
		}
	}
	.project-batches{
		background-color: #68C6DD;
	}
	.other-cases .case-nikesb {
		opacity: .5;
		.btn {
			pointer-events: none;
			cursor: default;
		}
	}
}

@include media-breakpoint-up(md) {
	.page-case.page-case-nikesb{
		.hero{
			.title{
				padding-top: 3rem;
				.teaser{
					margin-bottom: 6rem;
				}
				.display-1{
					font-size: 6rem;
				}
			}
		}
		.project-batches{
			.container{
				max-width: 1100px;
			}
			.container.container-narrow{
				max-width: 740px;
			}
			p{
				//color: #FFF;
			}
			.batch-01{
				margin-left: 4rem;
			}
			.batch-02{
				margin-left: -3rem;
			}
			.batch-03{
			}
		}
		.project-screens-responsive{
			.desktop-products-filters{
				margin-left: 8rem;
			}
			.mobile-products{
				margin-top: 1rem;
				margin-left: -4rem;
			}
		}
	}
}


/* ufs */

.page-case.page-case-ufs{
	.header-inverse{
		background: #e9590b;
		background: -webkit-linear-gradient(-45deg, #e9590b 30%,#f6a500 100%);
		background: linear-gradient(135deg, #e9590b 30%,#f6a500 100%);
		color: #FFF;
	}
	.hero{
		.title{
			padding-top: 1rem;
			.teaser{
				margin-bottom: 2rem;
			}
			.display-1{
				font-size: 4rem;
			}
		}
	}
	.featured-screen img{
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.feature-kitchen{
		background-image: url(../img/cases/unilever/kitchen.jpg);
		background-position: center 80%;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		img{
			max-width: 100%;
		}
	}
	.other-cases .case-ufs {
		opacity: .5;
		.btn {
			pointer-events: none;
			cursor: default;
		}
	}
}

@include media-breakpoint-up(md) {
	.page-case.page-case-ufs{
		.hero{
			.title{
				padding-top: 3rem;
				.teaser{
					margin-bottom: 6rem;
				}
				.display-1{
					font-size: 6rem;
				}
			}
		}
		.feature-kitchen{
			img{
				max-width: 500px;
			}
		}		
	}
}


/* case template 1 */

.page-case.page-case-1{
	.header-inverse{
		background-color: $black;
		color: #FFF;
	}
	.testimonial .quote{
		font-size: 2.5rem;
		font-weight: 900;
		line-height: 1.2;
		&::before {
			content: ' ';
			display: block;
			width: 44px;
			padding-bottom: ($spacer * 3);
			font-size: 20px;
			border-top: 6px solid $gray-500;
		}
	}
}

.page-case.page-case-1{
	.header-inverse{
		background-color: $black;
		color: #FFF;
	}
	.testimonial .quote{
		font-size: 2.5rem;
		font-weight: 900;
		line-height: 1.2;
		&::before {
			content: ' ';
			display: block;
			width: 44px;
			padding-bottom: ($spacer * 3);
			font-size: 20px;
			border-top: 6px solid $gray-500;
		}
	}
}










/* -------------------------- */

.logo{
	color: $black;
	height: 40px;
	width: auto;
}

@media (min-width: 992px) {
	header.container-fluid,
	footer > .container-fluid
	{

	}
	header {
		margin-top: 0px;
		margin-bottom: 60px;
		padding-top: 60px;
		padding-bottom: 40px;
	}
}

header .nav-link{
	color: $black;
}

.navbar {
	padding-left:0;
	padding-right: 0;
	margin-bottom: 2rem;
	padding-top: 1rem;
}
.navbar-toggler{
	padding-left:0;
	padding-right: 0;
}
.nav-item-lang{
	padding-top: 6px;
}
.nav-item-lang .nav-link{
	font-size: .85rem;
}

.nav-link{
	transition: all 0.2s ease-in-out;
}
	.nav-link:hover {
		text-decoration: none;
	}

	.nav-link > span {
		position: relative;
	}
	.nav-link > span:before,
	.nav-link > span:after {
		content: "";
		position: absolute;
		bottom: -4px;
		width: 0px;
		height: 2px;
		margin: 6px 0 0;
		transition: all 0.2s ease-in-out;
		transition-duration: 0.2s;
		opacity: 0;
		background-color: $black;
	}
	.nav-link > span:before {
		left: calc(50%);
	}
	.nav-link > span:after {
		right: calc(50%);
	}
	.nav-link:hover > span:before,
	.nav-link:hover > span:after {
		opacity: 1;
		width: 50%;
	}

.header-inverse{

	header .logo,
	header .menu-icon{
		fill: $white;
	}
	header .nav-link {
    	color: $white;
	}
	header .nav-link > span:before,
	header .nav-link > span:after {
		background-color: $white;
	}
	.hero{
		h1, p{
			color: $white;
		}
	}
}

.hero{
	padding-bottom: 60px;
}

.main{
	padding-bottom: 60px;
}

/* Sticky footer styles  */

html {
	position: relative;
	min-height: 100%;
}
body {
	margin-bottom: 60px; /* Margin bottom by footer height */
}
.footer {
	margin: 0;
	padding: 0;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px;
	line-height: 50px;
}