/*

====================================

Para compilar ejecutar (desde raiz del proyecto):

  $ sass src/styles.scss:webroot/assets/css/styles.css

para dejar a sass observando por cambios:

  $ sass --watch src:webroot/assets/css/

====================================

*/

// Import custom variables
@import "../../node_modules/bootstrap/scss/functions";
@import "bootstrap-variables";
//@import "ionicons-variables";

// Import Bootstrap for Sass
@import "../../node_modules/bootstrap/scss/bootstrap";

// Our Evil Stylesheets
@import "layout";

// Other Components
//@import "ionicons/ionicons";

